<template>
  <div class="mb-4">
    <b-field :type="$store.getters.hasErrors('slug').type">
      <b-input
        v-model="slug"
        :disabled="!editing"
        expanded
        class="mb-2"
      ></b-input>
    </b-field>
    <div class="buttons">
      <b-button
        v-if="!editing"
        size="is-small"
        :disabled="disabled || $store.getters.isProcessing"
        @click="onEdit"
        >Edit</b-button
      >
      <b-button v-else size="is-small" @click="onCancel">Cancel</b-button>
      <b-button size="is-small" v-if="editing" @click="onSave">Save</b-button>
      <b-button size="is-small" v-if="editing" @click="onAuto">Auto</b-button>
      <b-button v-if="saved_slug && editing" size="is-small" @click="onRestore"
        >Restore</b-button
      >
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    data: {
      type: String,
      required: true
    },
    saved_slug: {
      type: String,
      required: true
    },
    table: {
      type: String,
      required: true
    },
    column: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    name: "",
    slug: "",
    tempSlug: "",
    generatedSlug: "",
    savedLocaleSlug: "",
    autoGenerate: true,
    editing: false,
    saved: false,
    disabled: true
  }),
  computed: {},
  watch: {
    saved_slug() {
      if (this.saved_slug) {
        this.saved = true;
        this.savedLocaleSlug = this.saved_slug;
        this.slug = this.saved_slug;
        this.disabled = false;
      }
    },
    data: _.debounce(function() {
      if (this.data) {
        if (!this.saved && !this.savedLocaleSlug) {
          this.uniquify(this.slugify(this.data));
        }
      } else {
        this.slug = "";
      }
    }, store.getters.delay),
    slug() {
      this.$emit("on-slug-change", this.slug);
    }
  },
  methods: {
    slugify(data) {
      return slugify(data, {
        replacement: "-",
        lower: true,
        strict: true
      });
    },
    uniquify(data) {
      this.disabled = true;
      store
        .dispatch("slugify", {
          slug: data,
          table: this.table,
          column: this.column
        })
        .then(res => {
          this.slug = res;
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    onEdit() {
      this.editing = true;
      this.tempSlug = this.slug;
    },
    onCancel() {
      this.editing = false;
      this.slug = this.tempSlug;
    },
    onSave() {
      this.savedLocaleSlug = this.slug;
      this.editing = false;
      this.saved = true;
      if (this.slug != this.savedLocaleSlug)
        this.uniquify(this.slugify(this.slug));
    },
    onAuto() {
      this.saved = false;
      this.editing = false;
      this.uniquify(this.slugify(this.data));
    },
    onRestore() {
      this.slug = this.saved_slug;
      this.editing = false;
    }
  }
};
</script>
