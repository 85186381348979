<template>
  <div class="border mb-4">
    <div @click="openFilesBrowser">
      <b-image
        :src="image ? image.thumb : $default_image_thumb"
        alt="featured-image"
        ratio="1by1"
        lazy
      ></b-image>
    </div>
    <div class="p-4">
      <b-button size="is-small" @click="openFilesBrowser">Change</b-button>
      <b-button size="is-small" class="ml-2" @click="onRemove">Remove</b-button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { Events } from "@/events.js";
export default {
  props: {
    image_id: {
      type: Number
    },
    type: {
      type: String
    }
  },
  data: () => ({
    image: null,
    emit: "image-selected"
  }),
  watch: {
    image_id() {
      if (this.image_id) {
        store.dispatch("getFile", { id: this.image_id }).then(res => {
          this.image = res.data;
        });
      } else {
        this.image = null;
      }
    }
  },
  methods: {
    openFilesBrowser() {
      this.$fb.show({
        ids: [this.image_id],
        multiple: false,
        image_id: this.image_id,
        emit: this.emit,
        type: this.type
      });
    },
    onRemove() {
      Events.$emit(this.emit, []);
    }
  }
};
</script>
