<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Categories' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field
                label="Name"
                :type="$store.getters.hasErrors('name').type"
                :message="$store.getters.hasErrors('name').message"
              >
                <b-input v-model="name"></b-input>
              </b-field>
              <Slugger
                :data="name"
                :saved_slug="savedSlug"
                table="categories"
                column="slug"
                :errors="$store.getters.hasErrors('slug')"
                @on-slug-change="slug = $event"
              />
              <b-field label="Parent Category" v-if="notParent">
                <b-select v-model="category_id" placeholder="None" expanded>
                  <option :value="''">None</option>
                  <option
                    v-for="(category, index) in categories"
                    :key="index"
                    :value="category.id"
                    >{{ category.name }}</option
                  >
                </b-select>
              </b-field>
              <b-field label="Users">
                <b-taginput
                  v-model="usersWithAccess"
                  :data="filteredUsers"
                  @typing="getFilteredUsers"
                  autocomplete
                  field="name"
                  icon="label"
                  placeholder="Add users"
                >
                </b-taginput>
              </b-field>
              <b-field label="Video URL">
                <b-input v-model="video_url"></b-input>
              </b-field>
            </div>
          </div>
          <div class="px-2 w-4/12">
            <FeaturedImage :image_id="image_id" type="image" />
            <div class="p-4 border">
              <div class="flex items-center justify-end">
                <b-button
                  class="mr-4"
                  v-if="$route.params.id"
                  type="is-danger is-light"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onDelete()"
                  >Delete</b-button
                >
                <b-button
                  type="is-success"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import Slugger from "@/components/admin/Slugger";
import FeaturedImage from "@/components/admin/FeaturedImage";
import store from "@/store";
import { Events } from "@/events.js";
export default {
  components: {
    Sidebar,
    Slugger,
    FeaturedImage
  },
  data: () => ({
    name: "",
    slug: "",
    savedSlug: "",
    category_id: null,
    image_id: null,
    usersWithAccess: [],
    users: [],
    filteredUsers: [],
    categories: [],
    checkedCategories: [],
    notParent: true,
    video_url: null
  }),
  computed: {},
  watch: {},
  methods: {
    onSave() {
      store.dispatch("saveCategoriesForm", {
        name: this.name,
        slug: this.slug,
        id: this.$route.params.id || false,
        image_id: this.image_id || null,
        category_id: this.category_id || null,
        ids: _.map(this.usersWithAccess, "id"),
        video_url: this.video_url
      });
    },
    onDelete() {
      store.dispatch("deleteSelection", {
        message: `Do you really want to delete this category?`,
        ids: [this.$route.params.id],
        path: "categories",
        push: "Categories"
      });
    },
    getFilteredUsers(text) {
      this.filteredUsers = this.users.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    setCategoryImageId(ids) {
      this.image_id = ids.length ? ids[0] : null;
    }
  },
  beforeMount() {
    Events.$on("image-selected", this.setCategoryImageId);
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch, { id: to.params.id }).then(res => {
        next(vm => {
          vm.categories = res.data.categories;
          vm.users = res.data.users;
          vm.filteredUsers = res.data.users;
          if (to.params.id) {
            vm.name = res.data.category.name;
            vm.slug = res.data.category.slug;
            vm.savedSlug = res.data.category.slug;
            vm.category_id = res.data.category.category_id;
            vm.image_id = res.data.category.image_id;
            vm.notParent = res.data.category.children.length ? false : true;
            vm.checkedCategories = _.map(res.data.category.children, "id");
            vm.usersWithAccess = res.data.category.users;
            vm.video_url = res.data.category.video_url;
          }
        });
      });
    }
  }
};
</script>
